
/* Main container for the form */
.login-form-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: white;
    padding: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    border: 1px solid #E54D2E;
    border-radius: 15px;
    text-align: center;
    width: 100%; /* Allow it to fill the container */
    max-width: 500px; /* Set a maximum width */
    box-sizing: border-box;
    margin: 0 auto; /* Centering */
  }
  
  .login-form-container h1 {
    text-align: center;
    margin-bottom: 20px;
    color: #E54D2E;
  }
  
  /* Styling for the form */
  .login-form-container form {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 10px;
  
  }
  
  /* Styling for input fields */
  .login-form-container input {
    min-width: 90%;
    padding: 10px;
    font-size: 20px;
    box-sizing: border-box;
    border: 1px solid #E54D2E;
    border-radius: 4px;
    outline: none;
  }
  
  .login-form-container button {
    width: 100%;
    padding: 10px;
    background-color: #E54D2E;
    color: white;
    border: none;
    cursor: pointer;
    border-radius: 5px;
    font-size: 20px;
    box-sizing: border-box;
  }
  
  /* Button hover effect */
  .login-form-container button:hover {
    background-color: #C44428;
  }
  
  .products_btn {
    color: #E54D2E;
    background-color: #fff;
    font-weight: bold;
  
  }
  
  .abmelden_btn, .anmelden_btn {
    font-weight: bold;    
  }
  
  .outer-form {
    width: 100%;
  }
  