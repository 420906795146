* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: Arial, sans-serif;
}

body {
  background-color: #f0f0f0;
  color: #333;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  padding: 20px;
}

.container {
  position: relative;
  background-color: #fff;
  max-width: 2500px;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  padding: 40px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  width: 150vh;
  height: 90vh;
  background-color: white;
}

.text-section-container {
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  height: 85vh;
  width: 80vw;
  text-align: center;
}

.text-section {
  width: 95%;
  text-align: center;
  align-items: center;
  display: flex;
  flex-flow: column;
  justify-content: center; 
  margin-right: 20%;
}

.text-section .icon img {
  width: 80px;
  height: 80px;
  margin-bottom: 20px;
  display: flex; /* Enable flexbox layout */
  flex-flow: column;
  justify-content: center; /* Center content horizontally */
  align-items: center; /* Center content vertically */
  text-align: center; /* Center text inside the div */
}

.text-section h1 {
  font-size: 3.5em;
  margin-bottom: 20px;
  color: #E54D2E;
}

center-button {
  display: block;
  margin: 0 auto;
}