.image-grid {
  width: 90vh; /* This controls the width of the grid */
  display: flex; /* Enable flexbox layout */
  justify-content: flex-start; /* Align items to the start (left) */
  align-items: flex-start; /* Align items to the top */
  gap: 5px; /* Uncomment if you want spacing between items */
  margin: 0; /* Remove default margins, if any */
  padding: 0; /* Remove default padding, if any */
}

.column {
  display: flex;
  flex-direction: column;
  height: 85vh;
}

.column-1 img {
  max-width: 100%;
  max-height: 101%;
  /* width: auto; */
  height: 86vh;
  object-fit: contain;
  border-radius: 15px;
}

.column-2 {
  display: flex;
  flex-direction: column;
  gap: 0.6vh;
  margin-left: 25px;
}

.column-2 img {
  width: 34vh;
  height: 21vh;
  border-radius: 15px;
}